export const CREDIT_CARD = 'creditcard';
export const APPLE_PAY = 'applepay';
export const API_KEY = process.env.REACT_APP_API_KEY;
export const ONRAMPER_TRY_API_KEY = 'pk_prod_01GQS0CRGNRXXGV3A0S3A0AEWY';
export const DEFAULT_TOKEN_URL = 'https://cdn.onramper.com/default-token.svg';
export const EMPTY_ICON_URL = 'https://s3.amazonaws.com/cdn.onramper.com/empty.svg';
export const TURNSTILE_SITE_KEY = process.env.REACT_APP_TURNSTILE_SITEKEY;

// NOTE : Do not override !
export const ONRAMPER_WIDGET_API_KEY = process.env.REACT_APP_API_KEY;
export const COINIFY_OTC_URL = 'https://www.coinify.com/coinifyplus-signup/?utm_source=onramper'

export const GB_COUNTRY_CODE = 'GB';
