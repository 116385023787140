import * as endpoints from "../config/endpoints";
import { useParamContext, useTransactionContext } from "../providers";
import { PaymentMethodMetadata } from "../types";
import { useFetch } from "../utils/reactQuery";
import { FetchParams } from "./usePaymentTypes";

type PaymentMethodMetadataResponse = {
  message: PaymentMethodMetadata;
};

export const usePaymentMetadata = () => {
  const { transaction } = useTransactionContext();
  const {
    selectedCountry,
    transactionType,
    isRecurringPayment,
  } = transaction;
  const { params } = useParamContext();
  const { country } = params;

  const fetchParams: FetchParams = {
    type: transactionType ?? "buy",
    isRecurringPayment,
  };

  if (country) {
    fetchParams.country = country;
  } else if (selectedCountry) {
    fetchParams.country = selectedCountry.countryCode.toLowerCase();
  }

  const { data, isLoading, refetch } = useFetch<PaymentMethodMetadataResponse>(
    `${endpoints.paymentTypes}`,
    fetchParams,
    {
      enabled: false,
    }
  );
  return {
    paymentMetadata: data?.message,
    isLoading,
    refetch,
  };
};
